<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                            
                        <div class="row">
                            <div class="col-lg-2 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label="Region" clearable dense :disabled="disabled_region == true"  @change="(event) => regionOnChange(event)" prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="Branch Office" clearable dense :disabled="disabled_office == true"  @change="(event) => officeOnChange(event)" prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" label="Salesman" clearable dense :disabled="disabled_salesman == true" prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="typeModel" :items="typeLists" default="" item-value="value" item-text="text" label="Type" @change="(event) => typeOnChange(event)" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="reasonModel" :items="reasonLists" default="" item-value="str1" item-text="str2" label="Reason" clearable dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-6 col-6">
                                <!-- <v-btn class="mt-3 mr-3" color="green" elevation="2" small rounded @click="addNewOrderCancel()" dark>Add New<v-icon right dark>mdi-file-plus</v-icon></v-btn> -->
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            <div class="col-lg-12">
                                <div class="row text-left badgeInfo">
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-wallet"></span> IDR Total : {{ sumIdr ? sumIdr : '-'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-weight-kilogram"></span> Weight Total : {{ sumWgt ? sumWgt : '-'}} Tons</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">

                <div class="card elevation-5">
                    <div class="card-body">
                            
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <td class="text-left w-50">
                                        <v-text-field v-model="searchItem" append-icon="mdi-magnify" class="w-50" label="Search" dense single-line hide-details></v-text-field>
                                    </td>
                                    <td class="text-right w-50">
                                        <v-btn class="mt-3 text-white" color="#0D47A1" elevation="2" small dense @click="exportToExcel()">Export Excel<v-icon right dark>mdi-arrow-down-bold-box</v-icon></v-btn>
                                    </td>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.LENGTH`]="{ item }">
                                {{item.LENGTH + ' m'}} 
                            </template>
                            <template v-slot:[`item.WGT`]="{ item }">
                                {{item.WGT + ' Kg'}} 
                            </template>
                        </v-data-table>
                            
                    </div>
                </div>

            </div>

        </div>

    </div>
    
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Customer Order',
                disabled: false,
                href: '#',
                },
                {
                text: 'Missed Order List',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            regionLists: [],
            disabled_region: false,
            officeModel: '',
            officeLists: [],
            disabled_office: false,
            salesModel: '',
            salesLists: [],
            disabled_salesman: false,
            typeModel: '',
            typeLists: [
                {
                    text: 'ALL',
                    value: ''
                },
                {
                    text: 'OPERASIONAL',
                    value: 'O'
                },
                {
                    text: 'ADMINISTRATION',
                    value: 'A'
                }
            ],
            reasonModel: '',
            reasonLists: [],
            dateStartModel: '',
            dateEndModel: '',
            dateStartModal: false,
            dateEndModal: false,
            itemLists: [],
            headers: [
                { text: 'DATE', value: 'DATE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'CUSTOMER', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'OFFICE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'SALESMAN', align: 'left', class: 'primary--text blue lighten-5' },
                // { text: 'PROD. CODE', value: 'PROD_CODE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROD. DESC', value: 'PROD_DESC', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'LENGTH', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'QTY.', value: 'QTY', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT.', value: 'WGT', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL', value: 'SUBTOTAL', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'REASON', value: 'REASON', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'REMARK', align: 'right', class: 'primary--text blue lighten-5' },
                { text: '', value: 'action', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatable: false,
            searchItem: '',
            sumIdr: 0,
            sumWgt: 0,
            exportToExcelLists: [],

        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardResult").style.display = "none";

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancelList`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                }

                this.reasonLists = res.data.reason

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async getData(){

            this.$store.dispatch('setOverlay', true)
            $(".cardResult").hide()
            this.loadingDatatable = true
            this.itemLists = []
            this.exportToExcelLists = []

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderCancelList/getData`, { 

                region: this.regionModel ? this.regionModel : '',
                office: this.officeModel ? this.officeModel : '',
                sales: this.salesModel ? this.salesModel : '',
                type: this.typeModel ? this.typeModel : '',
                reason: this.reasonModel ? this.reasonModel : '',
                dtStart: this.dateStartModel ? this.dateStartModel : '',
                dtEnd: this.dateEndModel ? this.dateEndModel : '',

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                $(".cardResult").show()
                this.sumIdr = res.data.totalPrice
                this.sumWgt = res.data.totalWgt
                this.loadingDatatable = false
                this.itemLists = res.data.result
                this.exportToExcelLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async regionOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancelList/regionOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.salesLists = res.data.sales

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async officeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancelList/officeOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async typeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancelList/typeOnChange?type=${this.typeModel ? this.typeModel : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.reasonLists = res.data.reason

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        },

        exportToExcel(){

            const data = this.exportToExcelLists
            const fileName = "Missed-Order-List-Excel-Data"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })

        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>